import React, { RefObject, useEffect, useRef } from 'react';
import { TVideo } from '.';
import MuxPlayer from '@mux/mux-player-react';

type CarouselVideoProps = {
  containerRef?: RefObject<HTMLDivElement>;
  video: TVideo;
  currentVideo?: TVideo;
};

export const CarouselVideo = ({
  currentVideo,
  video,
  containerRef,
}: CarouselVideoProps) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (currentVideo === video && videoRef.current && containerRef?.current) {
      const left = videoRef.current.offsetLeft;
      containerRef.current.scrollTo({ left });
    }
    if (currentVideo !== video && playerRef.current) {
      playerRef.current.media.nativeEl.pause();
    }
  }, [containerRef, currentVideo, video]);

  return (
    <div ref={videoRef} className="flex snap-center aspect-square h-full z-50">
      <MuxPlayer
        muted={true}
        ref={playerRef}
        playbackId={video.url
          .replace('https://stream.mux.com/', '')
          .replace('.m3u8', '')}
      />
    </div>
  );
};

export default CarouselVideo;
