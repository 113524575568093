import { useEffect, useRef, useCallback, useState } from 'react';
import NextImage from 'next/image';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Portal } from '@headlessui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlayIcon,
} from '@heroicons/react/20/solid';
import { TImage, TMedia, TVideo } from '.';
import clsx from 'clsx';
import { IconButton } from '@fagmobler/ui';
import CarouselVideo from '@/components/imageCarousel/Video';

export type LightboxProps = {
  media: TMedia[];
  currentMedia: TMedia;
  onChangeMedia(media: TMedia): void;
  children?: never;
  onClose(): void;
};

export const Lightbox = ({
  media,
  onClose,
  currentMedia,
  onChangeMedia,
}: LightboxProps) => {
  const scrollContainerRef = useRef<HTMLUListElement | null>(null);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scroll({
        top: 0,
        left:
          window.innerWidth *
          media.findIndex((image) => image === currentMedia),
        behavior: firstLoad ? undefined : 'smooth',
      });
    }
    setFirstLoad(false);
  }, [currentMedia, media, firstLoad]);

  const handleNextImage = useCallback(() => {
    const nextImageIndex =
      (media?.findIndex((image) => image === currentMedia) || 0) + 1;
    if (media[nextImageIndex]) {
      onChangeMedia(media[nextImageIndex]);
    }
  }, [currentMedia, media, onChangeMedia]);

  const handlePrevImage = useCallback(() => {
    const nextImageIndex =
      (media?.findIndex((image) => image === currentMedia) || 0) - 1;
    if (media[nextImageIndex]) {
      onChangeMedia(media[nextImageIndex]);
    }
  }, [currentMedia, media, onChangeMedia]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') handleNextImage();
      if (event.key === 'ArrowLeft') handlePrevImage();
      if (event.key === 'Escape') onClose();
    },
    [handlePrevImage, handleNextImage, onClose]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Portal refName="lightbox">
        <div className="fixed top-0 left-0 w-full h-full z-50 overflow-hidden bg-neutral-0">
          {/* <div className="absolute top-0 l-0 w-screen h-screen bg-white opacity-90" /> */}
          <ul
            className="relative w-full top-0 overflow-scroll flex flex-row flex-nowrap snap-x"
            style={{ maxHeight: 'calc(100%-30rem' }}
            ref={scrollContainerRef}
          >
            {media?.map((image) => (
              <li
                key={`main${image.key}`}
                className="flex h-screen w-screen min-w-full p-10 snap-center items-center justify-center relative "
              >
                <div className="m-2 relative w-full h-[calc(100vh-20rem)]">
                  {image.assetType === 'video' ? (
                    <CarouselVideo video={image as TVideo} />
                  ) : (
                    <NextImage
                      src={image.url}
                      fill
                      alt={image.alt || 'Bilde'}
                      className="p-3 w-auto mix-blend-darken object-contain"
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
          <ul
            className={clsx(
              'absolute',
              'w-full',
              'bottom-10',
              'h-20',
              'flex flex-row flex-nowrap',
              'overflow-x-scroll',
              'justify-center',
              'py-3',
              'z-30',
              'box-content',
              'scrollbar-hide'
            )}
          >
            <li>
              <button
                onClick={handlePrevImage}
                className="flex justify-center items-center h-full w-8"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-5" />
              </button>
            </li>
            {media?.map((image) => (
              <li
                key={image.key}
                className={clsx(
                  'bg-neutral-30',
                  'w-20 h-20',
                  'relative',
                  'mr-5',
                  'rounded',
                  'box-border',
                  'flex justify-center items-center',
                  {
                    'outline outline-2 outline-offset-4 outline-neutral-60':
                      currentMedia === image,
                  }
                )}
              >
                <a
                  href={image.url}
                  onClick={(event) => {
                    onChangeMedia(image);
                    event.preventDefault();
                  }}
                  className={clsx(
                    'flex justify-center items-center',
                    'relative',
                    'w-16 h-16'
                  )}
                >
                  {image.assetType === 'video' ? (
                    <>
                      <NextImage
                        className="mix-blend-darken print:mix-blend-normal max-h-full max-w-full object-cover"
                        src={`https://image.mux.com/${image.url
                          .replace('https://stream.mux.com/', '')
                          .replace('.m3u8', '')}/thumbnail.png?width=64`}
                        width={4 * 16}
                        height={4 * 16}
                        alt={image.alt || 'Video'}
                      />
                      <PlayIcon className="size-[40%] text-white absolute" />
                    </>
                  ) : (
                    <NextImage
                      className="mix-blend-darken object-contain"
                      src={image.url}
                      width={64}
                      height={64}
                      alt={image.alt || 'Bilde'}
                    />
                  )}
                </a>
              </li>
            ))}
            <li>
              <button
                onClick={handleNextImage}
                className="flex justify-center items-center h-full w-8"
              >
                <ArrowRightIcon className="h-5 w-5" />
              </button>
            </li>
          </ul>
          <IconButton
            title="Lukk"
            size="lg"
            className="flex top-5 right-5 absolute z-30 bg-neutral-80 text-neutral-0"
            onClick={onClose}
          >
            <XMarkIcon className="w-5 h-5" />
          </IconButton>
        </div>
      </Portal>
    </>
  );
};
export default Lightbox;
