import { useEffect, useRef, useState } from 'react';
import CarouselImage from './Image';
import clsx from 'clsx';
import Lightbox from './Lightbox';
import { OmniumProduct } from '@/../../packages/omnium/dist/types';
import NextImage from 'next/image';
import CarouselVideo from '@/components/imageCarousel/Video';
import { PlayIcon } from '@heroicons/react/20/solid';

export type TImage = {
  assetType: 'image';
  url: string;
  key: string;
  alt?: string;
  lqip?: string;
};
export type TVideo = {
  assetType: 'video';
  url: string;
  key: string;
  alt?: string;
};
export type TMedia = TVideo | TImage;

export type ImageCarouselProps = {
  media: TMedia[];
  children?: never;
  alt: string[];
  mainImageSizes?: string;
  className?: string;
};

export const ImageCarousel = ({
  media,
  alt,
  mainImageSizes = '(max-width: 640px) 100vw, (max-width: 1024px) 336px, (max-width: 1256px) 432px, 656px',
  className,
}: ImageCarouselProps) => {
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState(false);
  const [currentMedia, setCurrentMedia] = useState<TMedia>(media[0]);
  const mainMediaContainerRef = useRef<HTMLDivElement>(null);

  const handleImageClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    // console.log('click', clickedImage.key);
    // setCurrentImage(clickedImage);
    setExpanded(true);

    event.preventDefault();
  };

  // Load large images only when interactive
  useEffect(() => {
    if (mainMediaContainerRef) {
      mainMediaContainerRef.current?.addEventListener(
        'mouseenter',
        () => {
          setActive(true);
        },
        { passive: true }
      );
      mainMediaContainerRef.current?.addEventListener(
        'touchstart',
        () => {
          setActive(true);
        },
        { passive: true }
      );
    }
    return () => {
      if (mainMediaContainerRef.current) {
        mainMediaContainerRef.current.removeEventListener('mouseenter', () => {
          setActive(true);
        });
      }
      if (mainMediaContainerRef.current) {
        mainMediaContainerRef.current.removeEventListener('touchstart', () => {
          setActive(true);
        });
      }
    };
  }, []);

  return (
    <>
      <div
        ref={mainMediaContainerRef}
        className={clsx(
          'relative',
          'aspect-square',
          'overflow-scroll',
          'flex',
          'flex-nowrap',
          'snap-x snap-mandatory',
          'box-content',
          'scroll-smooth',
          'scrollbar-hide',
          'print:w-full print:h-[5cm]',
          className
        )}
      >
        {media.map((image, i) => {
          if (i !== 0 && !active) return null;
          return image.assetType === 'video' ? (
            <CarouselVideo
              currentVideo={currentMedia as TVideo}
              containerRef={mainMediaContainerRef}
              video={image as TVideo}
              key={image.key}
            />
          ) : (
            <CarouselImage
              priority={i === 0}
              containerRef={mainMediaContainerRef}
              currentImage={currentMedia as TImage}
              onClick={handleImageClick}
              alt={alt[i] || alt[0]}
              image={image as TImage}
              key={image.key}
              sizes={mainImageSizes}
              className="print:w-full"
            />
          );
        })}
      </div>
      <div
        className={clsx(
          'relative',
          'overflow-scroll',
          'flex',
          'flex-nowrap',
          '-mx-4 px-4',
          'md:-mx-2 md:px-2 md:mr-0 md:pr-0',
          'mt-1 md:mt-0',
          'py-3 md:pt-2',
          'scrollbar-hide',
          'max-w-full',
          'space-x-2',
          'print:hidden'
        )}
      >
        {media.length > 1 &&
          media.map((image, i) => {
            return (
              <a
                key={image.key}
                href={image.url}
                onClick={(event) => {
                  setCurrentMedia(image);
                  setActive(true);
                  event.preventDefault();
                  return false;
                }}
                className={clsx(
                  'relative',
                  'flex justify-center items-center',
                  'bg-neutral-30',
                  'block',
                  'aspect-square',
                  'rounded',
                  'print:hidden',
                  'h-20 w-20',
                  'p-2',
                  'max-w-full',
                  {
                    'outline outline-2 outline-offset-2 outline-neutral-60':
                      image.key === currentMedia.key,
                  }
                )}
              >
                {image.assetType === 'video' ? (
                  <>
                    <NextImage
                      className="mix-blend-darken print:mix-blend-normal max-h-full max-w-full object-cover"
                      src={`https://image.mux.com/${image.url
                        .replace('https://stream.mux.com/', '')
                        .replace('.m3u8', '')}/thumbnail.png?width=64`}
                      width={4 * 16}
                      height={4 * 16}
                      alt={alt[i] || alt[0]}
                    />
                    <PlayIcon className="size-[40%] text-white absolute" />
                  </>
                ) : (
                  <NextImage
                    className="mix-blend-darken print:mix-blend-normal object-contain max-h-full max-w-full"
                    src={image.url}
                    width={4 * 16}
                    height={4 * 16}
                    alt={alt[i] || alt[0]}
                    placeholder={image.lqip ? 'blur' : undefined}
                    blurDataURL={image.lqip || undefined}
                  />
                )}
              </a>
            );
          })}
      </div>
      {expanded && (
        <Lightbox
          media={media}
          currentMedia={currentMedia}
          onChangeMedia={(media) => setCurrentMedia(media)}
          onClose={() => {
            setExpanded(false);
          }}
        />
      )}
    </>
  );
};

export default ImageCarousel;
