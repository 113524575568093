import { TNavigationItem } from '@/lib/sanity/queries';
import { getPathFromInternalLink } from '@/lib/utils/getUrls';
import clsx from 'clsx';
import Link from 'next/link';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MegaMenuAside } from './Aside';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

type MegaMenuPanelProps = {
  item: TNavigationItem;
  onClose(item: TNavigationItem | null): void;
  open: boolean;
};

export const MegaMenuPanel = ({ item, onClose, open }: MegaMenuPanelProps) => {
  const scrollAreaRef = useRef<HTMLUListElement>(null);
  const [scrolled, setScrolled] = useState(false);
  const [showScrollTip, setShowScrollTip] = useState(false);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  const handleScroll = () => {
    if (!scrollAreaRef.current) return;
    setScrolled(
      scrollAreaRef.current.scrollTop >=
        scrollAreaRef.current.scrollHeight - scrollAreaRef.current.clientHeight
    );
  };

  const handleClose = () => onClose(null);

  useEffect(() => {
    if (!scrollAreaRef.current) {
      return;
    }

    setShowScrollTip(
      scrollAreaRef.current.scrollHeight !== scrollAreaRef.current.clientHeight
    );
  }, [item]);

  return (
    <>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-80"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Overlay
          className=" bg-black/30 w-full h-[100vh] fixed left-0 top-32"
          onClick={handleClose}
        />
      </Transition>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 -translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-80"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-1"
      >
        <Popover.Panel
          className={clsx('absolute left-0 top-[3.45rem] z-20 max-h-full')}
        >
          <div className="bg-white flex">
            <div className="relative w-11/12">
              <ul
                ref={scrollAreaRef}
                onScroll={handleScroll}
                className={clsx(
                  'grid lg:grid-cols-3 xl:grid-cols-4 gap-10 auto-rows-min',
                  'p-10 relative',
                  'overflow-scroll max-h-[calc(100vh-10rem)]'
                )}
              >
                {item?.children?.map((parentItem: TNavigationItem) => {
                  const parentHref =
                    parentItem.internalLink &&
                    getPathFromInternalLink(
                      parentItem.internalLink,
                      parentItem
                    );
                  return (
                    <li
                      key={parentItem._key}
                      className="flex flex-col gap-1 text-neutral-80"
                    >
                      <ul
                        className={clsx('flex flex-col gap-3', {
                          'gap-0 font-500 font-heading bg-neutral-30 p-6':
                            !parentItem.title,
                        })}
                      >
                        <>
                          {parentItem.title && (
                            <li className="text-md font-500 font-heading">
                              {parentHref ? (
                                <Link
                                  href={parentHref}
                                  onClick={handleClose}
                                  className="block mb-1 underline leading-none un"
                                >
                                  {parentItem.title}
                                </Link>
                              ) : (
                                parentItem.title
                              )}
                            </li>
                          )}
                          {parentItem.children?.map(
                            (subItem: TNavigationItem) => {
                              const href =
                                subItem.internalLink &&
                                getPathFromInternalLink(
                                  subItem.internalLink,
                                  subItem
                                );

                              if (!href) {
                                return null;
                              }

                              return (
                                <li
                                  key={subItem._key}
                                  className={clsx({
                                    'pt-0 pb-3 border-b border-neutral-50 first:pt-0 first:pb-3 last:pt-0 last:pb-0 last:border-none':
                                      parentItem.title === '',
                                  })}
                                >
                                  <Link
                                    href={href}
                                    onClick={handleClose}
                                    className="hover:underline text-sm"
                                  >
                                    {subItem.title}
                                  </Link>
                                </li>
                              );
                            }
                          )}
                        </>
                      </ul>
                    </li>
                  );
                })}
              </ul>
              {showScrollTip && (
                <div className="flex justify-end absolute bottom-5 right-10">
                  <ChevronDownIcon
                    className={clsx(
                      'w-5 h-5 text-neutral-80 -mr-5 transition-opacity',
                      {
                        'opacity-0': scrolled,
                        'opacity-100': !scrolled,
                      }
                    )}
                  />
                </div>
              )}
            </div>
            <MegaMenuAside onClose={handleClose} key={item._key} />
          </div>
        </Popover.Panel>
      </Transition>
    </>
  );
};
